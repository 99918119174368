<template>
  <div
    class="ui-dropdown"
    :class="{isOpen}"
  >
    <div
      class="ui-dropdown-trigger"
      @click.stop="triggerClicked"
    >
      <slot
        name="trigger"
        :isOpen="isOpen"
        :open="doOpen"
        :close="doClose"
        :toggle="toggle"
      ></slot>
    </div>

    <div
      class="ui-dropdown-contents ui-card ui-z-1"
      v-if="isLoaded"
    >
      <slot
        name="contents"
        :isOpen="isOpen"
        :open="doOpen"
        :close="doClose"
        :toggle="toggle"
      ></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ui-dropdown",

  props: {
    open: {
      type: Boolean,
      required: false,
      default: false
    },

    trigger: {
      type: String,
      required: false,
      default: "click"
    },

    autoload: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      isOpen: this.open,
      isLoaded: this.autoload
    };
  },

  watch: {
    open: {
      immediate: true,
      handler(newValue) {
        if (this.isOpen == newValue) {
          return;
        }

        newValue ? this.doOpen() : this.doClose();
      }
    }
  },

  methods: {
    doOpen() {
      if (this.isOpen) {
        return;
      }

      this.isLoaded = true;
      this.isOpen = true;

      // window.addEventListener('click', this.clickListener);  // El event click se esta disparando de inmediato cuando doOpen fue disparado a su vez desde un @click
      // this.$nextTick(() => window.addEventListener('click', this.clickListener));
      setTimeout(
        () => window.addEventListener("click", this.clickListener),
        100
      );

      document.addEventListener("keydown", this.keydownListener);

      this.$emit("update:open", true);
      this.$emit("open");
    },

    doClose() {
      if (!this.isOpen) {
        return;
      }

      this.isOpen = false;
      window.removeEventListener("click", this.clickListener);
      document.removeEventListener("keydown", this.keydownListener);

      this.$emit("update:open", false);
      this.$emit("close");
    },

    toggle() {
      this.isOpen ? this.doClose() : this.doOpen();
    },

    triggerClicked() {
      if (this.trigger == "click") {
        this.toggle();
      }
    },

    // listen for outside click (click outside click - comentario para ayudar a buscar "click outside" en el codigo :))
    clickListener(event) {
      if (!this.$el.contains(event.target)) {
        this.doClose();
      }
    },

    // listen for ESC key press
    keydownListener(event) {
      switch (event.key) {
        case "Escape":
          this.doClose();
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.ui-dropdown {
  position: relative;

  & > .ui-dropdown-contents {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 200px;

    z-index: 999;
    margin: 0;

    max-height: 400px;
    overflow: auto;

    display: none;
  }

  &.isOpen {
    & > .ui-dropdown-contents {
      display: block;
    }
  }
}
</style>